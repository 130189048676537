import { computed, reactive, ref } from "@vue/reactivity";
import { nextTick, onMounted, unref } from "@vue/runtime-core";
import { store } from "@/store";
import Axios from "axios";
import { numberHelper, urlHelper } from "@/compositions/commonFunctions";
import moment from "moment";
import { notification } from "ant-design-vue";
import {
  requestAPI as objectAPI
} from "@/compositions/objects";

export function requestAPI() {
  // Получение списка бронирований
  async function fetchReservations(params) {
    try {
      return await Axios.get(`/reservations` + urlHelper.makeGetParams(params));
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

 // Получение списка избранных по типу
  async function fetchFavoriteObjectsByType() {
    try {
      return await Axios.get(`/objects/favorites`);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

   // Получение списка свободных по типу
   async function fetchFreeObjectsByType() {
    try {
      return await Axios.get(`/objects/free`);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Получение списка слотов объекта и его детей
  async function fetchFreeSlotsForObject(id, params) {
    try {
      return await Axios.get(`/objects/${id}/schedule` + urlHelper.makeGetParams(params));
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Получение списка слотов объекта и его детей
  async function fetchFreeSlots(id, params) {
    console.log(params);
    try {
      return await Axios.get(`/objects/${id}/children/schedule` + urlHelper.makeGetParams(params));
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Получение бронирования
  async function getReservationById(id) {
    try {
      return await Axios.get(`/reservations/${id}`);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Создание бронирования / Обновление по ID
  async function updateReservation(formData, id = null) {
    try {
      if (id) {
        return await Axios.put(`/reservations/${id}`, { ...formData });
      } else {
        return await Axios.post(`/reservations`, { ...formData });
      }
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Удаление бронирования по ID
  async function removeReservation(id) {
    try {
      return await Axios.delete(`/reservations/${id}`,);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Завершение бронирования по ID
  async function finishReservation(id) {
    try {
      return await Axios.post(`/reservations/${id}/end`,);
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Получение списка моих бронирований
  async function fetchMyReservations(params) {
    try {
      return await Axios.get(`/self/reservations` + urlHelper.makeGetParams(params));
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  return {
    fetchReservations, fetchFreeSlots, fetchFreeSlotsForObject, fetchMyReservations,
    getReservationById, updateReservation, removeReservation, finishReservation, fetchFavoriteObjectsByType, fetchFreeObjectsByType
  }
}

export function getListData() {
  const DEFAULT_INTERVAL = '04:00';
  const loading = ref(false);
  const { fetchReservations, fetchFreeSlots, fetchMyReservations } = requestAPI();

  const RESERVATION_STATUS = {
    'in_use': { value: 'in_use', text: 'Используется' },
    'reserved': { value: 'reserved', text: 'Забронирован' },
    'complete': { value: 'complete', text: 'Завершен' },
    'free': { value: 'free', text: 'Свободно' },
  };

  const reservationStatusOptionsUsed = [
    RESERVATION_STATUS.in_use,
    RESERVATION_STATUS.reserved,
    RESERVATION_STATUS.complete,
  ];

  const reservationStatusOptionsAll = [
    RESERVATION_STATUS.in_use,
    RESERVATION_STATUS.reserved,
    RESERVATION_STATUS.complete,
    RESERVATION_STATUS.free,
  ];

  const formSearchDate = reactive({
    // Бронирования
    dateFrom: null,
    timeFrom: null,
    timeFromValid: true,
    dateTo: null,
    timeTo: null,
    timeToValid: true,
    // Для свободных слотов отдельно
    freeDateFrom: null,
    freeTimeFrom: null,
    freeTimeFromValid: true,
    interval: DEFAULT_INTERVAL,
    intervalValid: true,
  });
  const formSearch = reactive({
    search: '',
    objectId: undefined,
    objectTypeId: undefined,
    objectTypeIds: undefined,
    userId: undefined,
    treeSearch: undefined, // undefined | all | map
    page: 1,
    dateFrom: computed(() => {
      // Для бронирований это дата + время
      if (unref(searchMode) === 'reservations') {
        if (!formSearchDate.dateFrom) return undefined;
        const time = formSearchDate.timeFrom || '00:00';
        const date = moment(`${formSearchDate.dateFrom} ${time}`, 'YYYY-MM-DD HH:mm');
        return date.isValid() ? date.format('YYYY-MM-DD HH:mm:00') : undefined;
      }
      // Для слотов только дата
      if (unref(searchMode) === 'free_slots') {
        if (!formSearchDate.freeDateFrom) return undefined;
        const date = moment(formSearchDate.freeDateFrom, 'YYYY-MM-DD');
        return date.isValid() ? date.format('YYYY-MM-DD') : undefined;
      }
      return undefined;
    }),
    timeFrom: computed(() => {
      if (unref(searchMode) === 'free_slots') {
        if (!formSearchDate.freeDateFrom || !formSearchDate.freeTimeFrom) return undefined;
        const date = moment(`${formSearchDate.freeDateFrom} ${formSearchDate.freeTimeFrom}`, 'YYYY-MM-DD HH:mm');
        return date.isValid() ? date.format('HH:mm') : undefined;
      }
      return undefined;
    }),
    dateTo: computed(() => {
      if (unref(searchMode) === 'reservations') {
        if (!formSearchDate.dateTo) return undefined;
        const time = formSearchDate.timeTo || '23:59';
        const date = moment(`${formSearchDate.dateTo} ${time}`, 'YYYY-MM-DD HH:mm');
        return date.isValid() ? date.format('YYYY-MM-DD HH:mm:00') : undefined;
      }
      return undefined;
    }),
    interval: computed(() => {
      if (unref(searchMode) === 'free_slots') {
        return unref(intervalMinutes);
      }
      return undefined;
    }),
    status: [],
  });
  // Переводим интервал вида HH:mm -> в минуты. Поддерживаются форматы 00:15, 65:34, 65.7883, если нужно
  const intervalMinutes = computed(() => moment.duration(formSearchDate.interval).asMinutes());

  const isFilterSelected = ref(false);
  const searchMode = ref('reservations'); // reservations | free_slots | my
  const clientMode = ref('admin'); // admin | user
  const filteredValue = ref([]);

  const columns = computed(() => [
    {
      title: 'Начало',
      dataIndex: 'timeFrom',
      key: 'timeFrom',
      width: '180px',
      slots: { customRender: 'time' },
    },
    {
      title: 'Окончание',
      dataIndex: 'timeTo',
      key: 'timeTo',
      width: '180px',
      slots: { customRender: 'time' },
    },
    ...[(unref(searchMode) === 'reservations' ?
        {
          title: 'Пользователь',
          slots: { customRender: 'user' },
        } : {}
    )],
    {
      title: 'Объект',
      slots: { customRender: 'objectName' },
    },
    {
      title: 'Код',
      slots: { customRender: 'objectCode' },
    },
    {
      key: 'status',
      title: 'Статус',
      dataIndex: 'status',
      width: '135px',
      filters: unref(searchMode) === 'reservations' ? reservationStatusOptionsUsed : [],
      filteredValue: unref(filteredValue),
      slots: { customRender: 'status' },
    },
    {
      className: 'text-right',
      width: unref(searchMode) === 'reservations' ? 'auto' : '160px',
      slots: { customRender: 'actions' },
    },
  ]);

  const data = ref([]);
  const pagination = reactive({ hideOnSinglePage: true });
  const rootLevel = ref({});

  onMounted(async () => {
    const res = await objectAPI().getObjectById('root');
    rootLevel.value = { ...res, name: 'Верхний уровень' };
    if (!formSearch.objectId) {
      formSearch.objectId = unref(rootLevel)?.id;
    }
  });

  async function initFilterDefaults() {
    // Возможно это лучше делать в контроллере
    if (!formSearch.objectId) {
      formSearch.objectId = unref(rootLevel)?.id;
    }
    // Поставим текущие даты
    formSearchDate.dateFrom = formSearchDate.dateTo = formSearchDate.freeDateFrom = moment().format('YYYY-MM-DD');
    formSearchDate.freeTimeFrom = undefined;
    formSearchDate.timeFrom = '00:00';
    formSearchDate.timeTo = '23:59';
    formSearchDate.interval = DEFAULT_INTERVAL;
  }

  function checkFilter() {
    // В зависимости от режима
    if (!formSearch.dateFrom) {
      return notification.error({
        message: 'Ошибка',
        description: 'Выберите дату и время начала для поиска',
      });
    }
    if (unref(searchMode) === 'reservations') {
      if (!formSearchDate.timeFromValid || !formSearchDate.timeToValid || formSearch.dateFrom >= formSearch.dateTo) {
        return notification.error({
          message: 'Ошибка',
          description: 'Проверьте, правильно ли выбраны даты',
        });
      }
      return true;
    } else if (unref(searchMode) === 'free_slots') {
      if (!formSearchDate.freeTimeFromValid || !unref(formSearchDate).intervalValid || unref(intervalMinutes) <= 0) {
        return notification.error({
          message: 'Ошибка',
          description: 'Проверьте, правильно ли выбрано время и продолжительность',
        });
      }
      return true;
    }
  }

  function checkIsFilterSelected() {
    isFilterSelected.value = formSearch.search.length > 0
      || !!formSearch.objectTypeId || !!formSearch.objectTypeIds || !!formSearch.userId
      || formSearch.interval !== moment.duration(DEFAULT_INTERVAL).asMinutes();
  }

  function reformatFreeSlotsToPlain(res) {
    const result = [];
    res.data.forEach((item) => {
      console.log('console.log(res?.data);',item);
      if (item.schedule.length > 0) {
        item.schedule.forEach((reserve) => {
          reserve?.availableIntervals.forEach((interval) => {

            result.push({
              id: numberHelper.uuid(),
             // userBy: res?.data?.reservedBy || '555cd162-76f8-4384-9380-ee03ee831aa4',
              userBy: item?.reservedBy,
              object: item?.object,
              status: "free",
              date: reserve?.date,
              timeFrom: interval?.from,
              timeTo: interval?.to,
              availableIntervals: reserve.availableIntervals,
            });
          });
        });
      }
    });
    return result;
  }

  function getPagination(meta) {
    if (unref(searchMode) === 'reservations') {
      pagination.pageSize = +meta?.perPage || 10;
      pagination.total = +meta?.total || 0;
      pagination.current = +meta?.currentPage || 1;
    } else if (unref(searchMode) === 'free_slots') {
      pagination.pageSize = 200;
      pagination.total = 200;
      pagination.current = 1;
    }
  }

  async function findReservation() {
    if (!checkFilter()) return false;
    loading.value = true;
    checkIsFilterSelected();

    let res;
    try {
      // Режим поиска для админа
      if (unref(clientMode) === 'admin') {
        // Бронирования
        if (unref(searchMode) === 'reservations') {
          res = await fetchReservations(unref(formSearch));
          data.value = res.data;

          // Поиск свободных слотов
        } else if (unref(searchMode) === 'free_slots') {
          const { objectId, ...payload } = unref(formSearch);
          if (objectId) {
            res = await fetchFreeSlots(objectId, payload);
            data.value = reformatFreeSlotsToPlain(res);

          } else {
            notification.error({
              message: 'Ошибка',
              description: 'Выберите объект для поиска свободных слотов',
            });
            loading.value = false;
            data.value = [];
            return false;
          }
        }
      }
      // Режим поиска для пользователя
      if (unref(clientMode) === 'user') {
        // Бронирования
        if (unref(searchMode) === 'reservations') {
          res = await fetchMyReservations(unref(formSearch));
          data.value = res.data;
          // Поиск свободных слотов
        } else {
          const { objectId, ...payload } = unref(formSearch);
          console.log(payload);
          if (objectId) {
            res = await fetchFreeSlots(objectId, payload);
            data.value = reformatFreeSlotsToPlain(res);

          } else {
            notification.error({
              message: 'Ошибка',
              description: 'Выберите объект для поиска свободных слотов',
            });
            loading.value = false;
            data.value = [];
            return false;
          }
        }
      }
      getPagination(res?.meta);
    } catch (e) {
      // searchMode.value = 'reservations';
      console.error(e);
    } finally {
      loading.value = false;
    }
  }

  async function resetFilters(reload) {
    await initFilterDefaults();
    //
    formSearch.page = 1;
    formSearch.search = '';
    formSearch.objectTypeIds = formSearch.objectTypeId = formSearch.userId = formSearch.status = undefined;
    formSearch.objectId = unref(rootLevel)?.id;
    setStatusFilter([]);
    isFilterSelected.value = false;
    if (reload) {
      await findReservation();
    }
  }

  async function handleTableChange(tablePagination, filters, sorter) {
    console.log(filters);
    console.log(sorter);
    formSearch.page = tablePagination.current;
    pagination.current = tablePagination.current;
    if (sorter) {
      // orderBy[sorter.field] = sorter.order;
    }
    if (filters) {
      setStatusFilter(filters.status);
    }
    await findReservation();
  }

  function setStatusFilter(statuses) {
    filteredValue.value = statuses;
    formSearch.status = statuses;
  }

  function dateChanged(field) {
    if (field === 'dateFrom') {
      if (formSearchDate.dateFrom && !formSearchDate.timeFrom) {
        formSearchDate.timeFrom = '00:00';
      }
    }
    if (field === 'dateTo') {
      if (formSearchDate.dateTo && !formSearchDate.timeTo) {
        formSearchDate.timeTo = '23:59';
      }
    }
    if (formSearchDate.dateTo < formSearchDate.dateFrom) {
      nextTick(() => {
        formSearchDate.dateTo = formSearchDate.dateFrom;
      });
    }
  }

  function timeChanged(field) {
    if (field === 'timeFrom') {
      if (formSearchDate.timeFrom && !formSearchDate.dateFrom) {
        formSearchDate.dateFrom = moment().format('YYYY-MM-DD');
      }
    }
    if (field === 'timeTo') {
      if (formSearchDate.timeTo && !formSearchDate.dateTo) {
        formSearchDate.dateTo = formSearchDate.dateFrom || moment().format('YYYY-MM-DD');
      }
    }
  }

  function disabledDateTo(current) {
    return current && current < moment(formSearch.dateFrom);
  }

  return {
    DEFAULT_INTERVAL, RESERVATION_STATUS,
    loading, data, columns, pagination, isFilterSelected, rootLevel,
    searchMode, clientMode,
    formSearch, formSearchDate, findReservation, resetFilters, handleTableChange, setStatusFilter,
    initFilterDefaults, dateChanged, timeChanged, intervalMinutes,
    reservationStatusOptionsAll,
    disabledDateTo,
  }
}

export function getOneData() {

  function reservationFilters(availableIntervals, allowReserveTime) {
    if (availableIntervals?.length > 0 && allowReserveTime) {
      const { from, to } = availableIntervals[0];
      return {
        dateFrom: moment(from, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD'),
        timeFrom: moment(from, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm'),
        dateTo: moment(to, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD'),
        timeTo: moment(to, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm'),
      };
    }
    return [];
  }

  return { reservationFilters, }
}
